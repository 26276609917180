<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Faculty
            <v-spacer></v-spacer>
            <add-button
              permission="grade-create"
              @action="
                (dialog = true,editId = null)
              "
              >New Faculty
            </add-button>
          </v-card-title>
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="data"
            :server-items-length="data.length"
            :loading="isLoading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td class="text-left text-bold">
                  <strong>{{ item.name }}</strong>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 1"
                    color="success"
                  >
                    Active</v-chip
                  >
                  <v-chip
                    small
                    label
                    outlined
                    v-if="item.status === 0"
                    color="error"
                  >
                    Inactive</v-chip
                  >
                </td>
                <td
                  class="text-right"
                  
                >

                  <edit-button
                    v-if="item.allowed"
                    permission="grade-update"
                    @agree="editFaculty(item)"                   
                  />
                  <delete-button
                    v-if="item.allowed"
                    :permission="true"
                    @agree="deleteFaculty(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
              <v-select
                id="affiliation_filter"
                label="Affiliation List"
                item-text="title"
                item-value="value"
                class="pa-0 affiliation_filter"
                outlined
                dense
                v-model="selectedAffiliation" 
                :items="affiliations"
                :loading="affiliationLoading"
                :disabled="true"
              />

              <v-text-field
                autocomplete="off"
                label="Name*"
                required
                class="pa-0"
                outlined
                dense
                v-model="facultyRecord.name"
                name="name"
                :rules="[(v) => !!v || 'Name is required']"
              />

              <v-select
                :items="statuses"
                required
                class="pa-0"
                label="Status*"
                outlined
                dense
                v-model="facultyRecord.status"
                :rules="[(v) => !!v || 'Status is required']"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(dialog = false)"
              >Close</v-btn
            >
            <v-btn
              :loading="saving"
              color="success"
              text
              @click="validate"
              >{{ editId === null ? 'Save' : 'Update' }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";

export default {
	mixins: [Mixins],
	data: () => ({
		saving: false,
		valid: true,
		lazy: false,
		isLoading: false,
		//. Data stored here. Data information
		facultyRecord:{
			name:"",
			status:"1",
			type:"faculty",
			sub_id:"",     
		},
		data: [],
		dialog: false,
		editId: null,
		headers: [
			{ text: "#", align: "left", value: "id", width: 50, sortable: false },
			{ text: "Name", align: "left", value: "name", sortable: false },
			{ text: "Status", align: "center", value: "rank", width: 110 },
			{ text: "Action", align: "right", sortable: false, width: 200 },
		],
		statuses: [
			{
				text: "Active",
				value: "1",
			},
			{
				text: "Inactive",
				value: "0",
			},
		],
		affiliationLoading: false,
		affiliations: [],
		selectedAffiliation: null,
	}),
	mounted() {
		this.selectedAffiliation = this.$route.params.affiliationId
			? parseInt(this.$route.params.affiliationId, 10)
			: "";
		this.fetchAffiliation();
		this.get();
	},

	methods: {
		validate() {
			if (this.$refs.form.validate()) {
				this.save();
			}
		},

		/**
     * Fetch affiiation list to show them on select option
     */
		fetchAffiliation() {
			this.affiliationLoading = true;
			this.$rest
				.get("/api/affiliation-faculty?type=affiliation")
				.then(({ data }) => {
					this.affiliations = data.data.map((res) => {
						return { title: res.name, value: res.id };
					});
				})
				.finally(() => {
					this.affiliationLoading = false;
				});
		},


		/**
     * Fetch the list of faculties to display on data table
     */
		get() {
			const extraParams = "?type=faculty&sub_id=" + this.selectedAffiliation;
			this.isLoading = true;
			this.$rest.get('/api/affiliation-faculty' + extraParams).then((res) => {
				this.data = res.data.data;
			}).finally(() => {
				this.isLoading = false;
			})
		},

		/**
     * To save the data in the table.
     */
		save() {
			this.facultyRecord.sub_id = this.selectedAffiliation;
			this.saving = true

			if (this.editId === null) {
				// If editId is null then this code will run.
				this.$rest.post("/api/affiliation-faculty", this.facultyRecord).then(() => {
					this.$events.fire('notification', { message: 'Faculty was created successfully', status: 'success' });
					this.resetAfterSaveUpdate();
				}).finally(() => {
					this.saving = false;
				})
			} else {
				// If editId is not null then this code will run.
				this.$rest.put("/api/affiliation-faculty/" + this.editId, this.facultyRecord).then(() => {
					this.$events.fire('notification', { message: 'Faculty was edited successfully', status: 'success' });
					this.resetAfterSaveUpdate();
				}).finally(() => {
					this.saving = false;
				}) 
			}
		},

		resetAfterSaveUpdate() {
			this.get();
			this.saving = false;
			this.dialog = false;
			this.facultyRecord.name = "";
			this.facultyRecord.status = "1";
		},

		// To delete the data from the table.
		deleteFaculty(id) {
			this.$rest.delete('/api/affiliation-faculty/' + id).then((res) => {
				this.get();
				this.$events.fire('notification', { message: 'Faculty was deleted.', status: 'success' });
			}) 
			console.log(this.$rest)
		},

		// To edit the data from the table. editFaculty is used in edit button.
		editFaculty(item) {
			this.facultyRecord.name = item.name;
			this.facultyRecord.status = "1";
			this.dialog = true;
			this.editId = item.id;
		}
	},
};
</script>
